<template>
  <div class="page-bg">
    <div class="page-container">
      <router-link to="/">
        <img src="/img/logo_bike.svg" height="61" />
      </router-link>
      <div class="card mt-3">
        <div class="card-body">
          <form class="text-center">
            <h2>Definição de senha</h2>
            <p>Preencha os campos abaixo com sua nova senha de acesso.</p>

            <label for="inputSenha" class="sr-only">Senha</label>
            <input
              type="password"
              id="inputSenha"
              class="form-control mb-2"
              placeholder="Nova senha"
              v-model="senha"
              autofocus
            />

            <label for="confirmarSenha" class="sr-only">Confirmar senha</label>
            <input
              type="password"
              id="confirmarSenha"
              class="form-control"
              placeholder="Confirme a nova senha"
              v-model="confirmarSenha"
              autofocus
            />

            <button
              @click.prevent="onClickConfirm"
              :disabled="loading"
              class="btn btn-primary btn-block mt-2"
            >
              <span v-if="!loading">Confirmar</span>
              <span v-if="loading">
                <small class="text-purple">Aguarde...</small>
              </span>
            </button>

            <router-link to="/" class="mt-5 d-block">Voltar para a tela inicial</router-link>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";

import config from "../config";
import Auth from "../core/auth";
import captureError from "../helpers/captureError";

export default {
  data() {
    return {
      loading: false,
      senha: "",
      confirmarSenha: "",
      token: "",
    };
  },
  async created() {
    this.token = this.$route.query.p;

    if (!this.token) {
      await Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          "Este link de redefinição de senha está expirado. Por favor, solicite um novo link.",
      });

      //Desloga e leva para a tela de login
      Auth.logout();
      this.$router.replace("/");
    }
  },
  methods: {
    async onClickConfirm() {
      if (!this.senha || !this.confirmarSenha) {
        return;
      }

      if (this.senha !== this.confirmarSenha) {
        Swal.fire({
          icon: "warning",
          title: "As senhas não conferem",
          text:
            "Verifique se você digitou corretamente a senha e a confirmação de senha.",
        });
        return;
      }

      if (this.senha.length < 6) {
        Swal.fire({
          icon: "warning",
          title: "Atenção",
          text: "Informe uma senha com no mínimo 6 dígitos.",
        });
        return;
      }

      this.loading = true;

      try {
        var data = {
          token: this.token,
          novaSenha: this.senha,
        };
        var response = await axios.post(
          `${config.API_URL}/auth/definirSenha`,
          data
        );

        await Swal.fire({
          icon: "success",
          title: "Senha definida com sucesso",
        });

        //Loga automaticamente e leva para a home
         Auth.setUserInfo(response.data);

          this.$emit("login-success");

        this.$router.replace("/");
      } catch (e) {
        this.loading = false;

        if (e.response && e.response.data && e.response.data.expirado) {
          await Swal.fire({
            icon: "error",
            title: "Oops...",
            text:
              "Este link de redefinição de senha está expirado. Por favor, solicite um novo link.",
          });

          //Desloga e leva para a tela de login
          Auth.logout();
          this.$router.replace("/");
          return;
        }

        captureError(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível redefinir a senha. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
  },
};
</script>


<style scoped>
.page-container {
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  padding: 12px;
  padding-top: 7vh;
}

.page-bg {
  background-color: #1c1c1b;
  background-image: url(/img/fundo_home.jpg);
  background-size: cover;
  min-height: 100vh;
}
</style>
